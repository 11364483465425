import React from 'react'
import { IMAGE } from '../../utils/Theme'

const AppDownload = () => {
  return (
    <section className="dwnld-app">
        <div className="container">
            <div className="row align-items-center">
            <div className="col-lg-6">
                <h6>DOWNLOAD THE APP</h6>
                <h3>Dry Cleaning and Laundry <br/>Made Simple</h3>
            </div>
            <div className="col-lg-3 store-bx">
                <a href="https://apps.apple.com/us/app/shine-silver-automatic-laundry/id6503691986" target='_blank'> 
                <img src={IMAGE.appleIcon} alt="" />
                DOWNLOAD ON THE APP STORE
                </a>
            </div>
            <div className="col-lg-3 store-bx">
                <a href="https://play.google.com/store/apps" target='_blank'> 
                <img src={IMAGE.androidIcon} alt="" />
                DOWNLOAD APP ON GOOGLE PLAY
                </a>
            </div>
            </div>
        </div>
</section>
  )
}

export default AppDownload