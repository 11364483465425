import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IMAGE } from '../../utils/Theme'
import { UpdateProfileRequest } from '../../redux/reducer/FetchDataReducer'
import { AreaAction } from '../../redux/reducer/ProductReducer'

const Profile = () => {
 
  const fetchReducer = useSelector((state) => state.FetchDataReducer)

  const dispatch = useDispatch()

  const [fname, setfname] = useState(fetchReducer?.GetProfileResponse?.userData?.firstName)
  const [lname, setlname] = useState(fetchReducer?.GetProfileResponse?.userData?.lastname)
  const [phone, setphone] = useState(fetchReducer?.GetProfileResponse?.userData?.phone)
  const [gender, setgender] = useState(fetchReducer?.GetProfileResponse?.userData?.gender)
  const [dob, setdob] = useState(new Date(fetchReducer?.GetProfileResponse?.userData?.dob))
  const [bname, setbname] = useState(fetchReducer?.GetProfileResponse?.userData?.detailsData?.building_name)
  const [fno, setfno] = useState(fetchReducer?.GetProfileResponse?.userData?.detailsData?.flat_no)
  const [street, setstreet] = useState(fetchReducer?.GetProfileResponse?.userData?.detailsData?.street)
  const [city, setcity] = useState(fetchReducer?.GetProfileResponse?.userData?.detailsData?.city)
  const [area, setarea] = useState(fetchReducer.GetProfileResponse?.userData?.detailsData?.zone)

const SubmitHandle  = async ()=>{
  let body = {
    "firstName": fname,
    "lastName":lname,
    "dob": dob,
    "gender": gender,
    "buildingname": bname,
    "flatno": fno,
    "street": street,
    "city": city,
    "zone":area
}

dispatch(UpdateProfileRequest(body))

}

const ZoneHandling =  (e) =>{
    
    dispatch(AreaAction(e.target.value))
    setarea(e.target.value)
    localStorage.setItem('zone-area', e.target.value)
  }


  return (
    <section class="prfl-sec">
    <div class="container">
        <h3>Profile</h3>
        <div class="row">
             <div class="col-lg-8">
            <div class="img-upload">
                <div class="img-upld-c">
                    <img src={fetchReducer?.GetProfileResponse?.userData?.profileImage} width="150" alt="profile"/>
                        {/* <input type="file" class="default-file-input" />
                         <span><img src={IMAGE.upld} alt=""/> </span> */}
                    </div>

                <div class="profile-details">
                    <h5>{fetchReducer?.GetProfileResponse?.userData?.firstName} {fetchReducer?.GetProfileResponse?.userData?.lastname}</h5>
                    <h6>{fetchReducer?.GetProfileResponse?.userPlan?.packageId?.packageName}</h6>
                    
                 
                </div>

            </div>
            </div>
          
            <div class="col-lg-6">

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> First Name</label>
                    <input type="text" class="form-control" placeholder="First Name"
                    value={fname}
                    onChange={(e)=>setfname(e.target.value)}
                    />
                </div>

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Last Name</label>
                    <input type="text" class="form-control" placeholder="Last Name"
                      value={lname}
                      onChange={(e)=>setlname(e.target.value)}
                    />
                </div>

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Phone Number</label>
                    <input type="text" class="form-control" placeholder="XXXXXX"
                    maxLength={10}
                     value={phone}
                     onChange={(e)=>setphone(e.target.value)}
                     readOnly
                    />
                </div>


                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Gender</label>
                    <select className='form-control'
                        value={gender}
                        onChange={(e)=>setgender(e.target.value)}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                </div>

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Birthday</label>
                    <input type="date" id="datepicker" class="datepicker"
                    value={dob}
                    onChange={(e)=>setdob(e.target.value)}
                    />
                </div>



            </div>

            <div class="col-lg-6">

                {/* <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Area</label>
                    <input type="text" class="form-control" placeholder="Sector V"/>
                </div> */}
                   <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Select Area</label>
                    <select className="form-select" onChange={ZoneHandling}  value={area}>
                    <option selected value="zone">Choose Your Area</option>
                    {fetchReducer.ZoneListResponse?.data?.areas&&fetchReducer.ZoneListResponse?.data?.areas.map((item,index)=>{
                            return (
                              <option key={index} value={item?._id}>{item?.area}</option>
                            )
                          })}
                    </select>
                </div>

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Building Name</label>
                    <input type="text" class="form-control" placeholder="Enter building name"
                        value={bname}
                        onChange={(e)=>setbname(e.target.value)}
                    />
                </div>

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Flat No</label>
                    <input type="text" class="form-control" placeholder="Enter Flat no"
                     value={fno}
                     onChange={(e)=>setfno(e.target.value)}
                    />
                </div>

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Street or Landmark</label>
                    <input type="email" class="form-control" placeholder="Enter Street or Landmark"
                      value={street}
                      onChange={(e)=>setstreet(e.target.value)}
                    />
                </div>

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> City</label>
                    <input type="text" class="form-control" placeholder="Enter City"
                      value={city}
                      onChange={(e)=>setcity(e.target.value)}
                    />
                </div>

                <button class="submt-btn-rvw" onClick={SubmitHandle}>UPDATE PROFILE</button>

       



            </div>
        </div>
    </div>
  
</section>
  )
}

export default Profile