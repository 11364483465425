import React, { useEffect, useState } from 'react'
import { IMAGE } from '../../utils/Theme'
import { NavLink, useNavigate } from 'react-router-dom'
import {ZoneRequest, GetProfileRequest} from '../../redux/reducer/FetchDataReducer'
import { useSelector, useDispatch } from 'react-redux'
import { AreaAction } from '../../redux/reducer/ProductReducer'
import Dropdown from 'react-bootstrap/Dropdown';

const TopHeader = () => {
 

  const token = localStorage.getItem('access_token');
  
  const fetchReducer = useSelector((state) => state.FetchDataReducer)
  const fetchproductReducer = useSelector((state) => state.Productdatareducer)
  const [area, setarea] = useState(fetchReducer.GetProfileResponse?.userData?.detailsData?.zone)
  const dispatch = useDispatch()
  let navigate = useNavigate()


  useEffect(()=> {
    dispatch(ZoneRequest())
    dispatch(GetProfileRequest())
   
  },[])

  const ZoneHandling =  (e) =>{
    
    dispatch(AreaAction(e.target.value))
    setarea(e.target.value)
    localStorage.setItem('zone-area', e.target.value)
  }


  return (
    <div className="header-top">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3 col-sm-12 right_info">
            <NavLink to="/" className="logo-img"><img src={IMAGE.logo}/></NavLink>
          
          </div>
          <div className="col-md-4 col-sm-12">
                <div className='right_info choose-top'>
                <span><img src={IMAGE.location} alt=""/></span>

                    <select className="form-select" onChange={ZoneHandling}  value={area}>
                    <option selected value="zone">Choose Your Area</option>
                    {fetchReducer.ZoneListResponse?.data?.areas&&fetchReducer.ZoneListResponse?.data?.areas.map((item,index)=>{
                            return (
                              <option key={index} value={item?._id}>{item?.area}</option>
                            )
                          })}
                    </select>
                </div>

           
          </div>
          <div className="col-md-5 col-sm-12 left_info">
            <ul>
            {token&&
              <li><NavLink to="/wallet"><img src={IMAGE.wallet}/> Wallet</NavLink></li>
            }
              {/* <li className="cntr-bx">
              <NavLink to="/">
           
                    <img src={IMAGE.shoppingCart}/>
                    </NavLink>
                <span>{!fetchReducer?.productListstatus ? 0 : fetchproductReducer?.serviceDry?.qty + fetchproductReducer?.serviceWash?.qty + fetchproductReducer?.serviceIron?.qty}</span>
              </li> */}
              {/* <li className="n-cntr-bx">
              <NavLink to="/">
                     <img src={IMAGE.notificationIcon}/>  
              </NavLink>
              <span>0</span>
            
              </li> */}
              {/* <li>
              <NavLink to="/">
              <img src={IMAGE.language}/>
                </NavLink>
              
                </li> */}

            </ul>
            {!token ? 
            <button className="login-top-btn"
            onClick={()=>{
              navigate('/login')
            }}
            >
                 Log In 
            </button>
        
     :  
          <NavLink to="/profile">
          <div className='dropdown-profile'>
          <div className='dropdown-profile_img'>
              <img src={fetchReducer?.GetProfileResponse?.userData?.profileImage}/>
            </div>
            <div className='dropdown-profile-info'>
                <span>Welcome</span>
                <h5>{fetchReducer?.GetProfileResponse?.userData?.firstName}</h5>
            </div>
        </div>
        </NavLink>
   
            }
          </div>

        </div>
      </div>
    </div>
  )
}

export default TopHeader