import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ChooseproductList: {},
    serviceDry:{},
    serviceWash:{},
    serviceIron:{},
    ZoneArea:'',
    mno:''
  };


  export const Productdatareducer = createSlice({
    name: 'Productdatareducer',
    initialState,
    reducers: {
        ProductAction(state, action) {
            state.ChooseproductList = action.payload;
        },

        serviceDryAction(state, action) {
            state.serviceDry = action.payload;
        },

        serviceWashAction(state, action) {
            state.serviceWash = action.payload;
        },

        serviceIronAction(state, action) {
            state.serviceIron = action.payload;
        },

        AreaAction(state, action) {
            state.ZoneArea = action.payload;
        },

        SavemobileNoAction(state, action) {
            state.mno = action.payload;
        },

    }
  })

  export const { ProductAction, serviceDryAction, serviceWashAction, serviceIronAction, AreaAction, SavemobileNoAction } = Productdatareducer.actions;

  export default Productdatareducer.reducer;