import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import { SlotDetailsRequest, GetCouponRequest, SlotpaymentRequest } from '../../redux/reducer/FetchDataReducer';
import moment from "moment";
import { toast } from 'react-toastify';
import Loader from '../../utils/Loader'
import ConfirmOrderSummery from './ConfirmOrderSummery';

const ConfirmOrder = () => {
    const [TotalAmount, setTotalAmount] = useState("")
    const [isapply, setisapply] = useState(null)
    const [want, setwant] = useState("folded")
    const [additional, setadditional] = useState("")

    const fetchReducer = useSelector((state) => state.FetchDataReducer)
    const zone = localStorage.getItem('zone-area');

    var orderdetails = fetchReducer.SlotDetailsResponse?.data
    var couponsList = fetchReducer?.GetCouponResponse?.data?.coupons



    console.log(isapply )

    let navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
       
            let obj = {
                "slotItemId":fetchReducer?.GetSlotListResponse?.data?.itemId
            }

            dispatch(SlotDetailsRequest(obj))
            dispatch(GetCouponRequest())
    },[])

  

    useEffect(()=>{
        if(orderdetails?.itemDetails == false){
            navigate("/dashboard")
        } else {
            setTotalAmount(fetchReducer.SlotDetailsResponse?.data?.itemDetails?.sub_total?.$numberDecimal)
        }
    },[fetchReducer.SlotDetailsResponse])

    const CouponHandle = (list,amount)=>{

        setisapply(list);
   
        var _amount = Number(amount)
        var rawAmount = fetchReducer.SlotDetailsResponse?.data?.itemDetails?.sub_total?.$numberDecimal
        var TotalDiscount = rawAmount - _amount
        setTotalAmount(TotalDiscount)
    }


    const PayHandle = ()=>{
        let obj= {
            "status": "success",
            "tempOrderId":  fetchReducer?.GetSlotListResponse?.data?.itemId,
            "discount": isapply?.discount?.$numberDecimal,
            "coupon": isapply?.couponCode,
            "payableAmount": TotalAmount,
            "paymentMethod": "wallet",
            "isAdditional": want,
            "special_instruction": additional
        }

        dispatch(SlotpaymentRequest({obj: obj,navigate:navigate}))
    }


  return (
    <>
    <Loader visible={fetchReducer.isloading} />
    
    <section className="dash-pills">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 track-sec">
                <h3>Confirm order</h3>
                    <h5><b>Order Amount : AED {orderdetails?.itemDetails?.sub_total?.$numberDecimal}</b></h5>

                    <div className="date-sec">

                        <div className="pickup-date">
                           
                            <div className="mb-3">
                                <p>Pick Up Date </p>
                                <span>{ moment(orderdetails?.slotDetails?.pickUpDate).format("DD-MM-YYYY")}</span>
                            </div>
                            <div className="mb-3">
                                <p>Pick Up Time </p>
                                <span>{orderdetails?.slotDetails?.pickUpTime}</span>
                            </div>
                        </div>
                        <div className="delivery-date">
                            <div className="mb-3">
                                <p> Delivery Date </p>
                                <span>{ moment(orderdetails?.slotDetails?.deliveryDate).format("DD-MM-YYYY")}</span>
                            </div>
                            <div className="mb-3">
                                <p>Delivery Time </p>
                                <span>{orderdetails?.slotDetails?.deliveryTime}</span>
                            </div>
                        </div>

                    </div>
                    <div className="qus-box">
                     <h3>How do you want yours clothes to be done?</h3>
                     <div className="d-flex gap-4">
                        <div className="form-check mb-3 mr-4">
                            <input className="form-check-input" name='additional' type="radio" value="folded" id="flexCheckfolded" onChange={(e)=>setwant(e.target.value)} 
                            checked={want === "folded"}
                            />
                            <label className="form-check-label" htmlFor="flexCheckfolded">
                                Folded
                            </label>
                          </div>

                          <div className="form-check mb-3">
                            <input className="form-check-input" name='additional' value="hanger" type="radio"  id="flexCheckhanger" onChange={(e)=>setwant(e.target.value)}
                            checked={want === "hanger"}
                            />
                            <label className="form-check-label" htmlFor="flexCheckhanger">
                                Hanger
                            </label>
                          </div>
                     </div>
                     <div className="mb-3">
                       <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                       placeholder="any special instructions"
                       value={additional}
                       onChange={(e)=>setadditional(e.target.value)}
                       ></textarea>
                      </div>
                  </div>
                  <NavLink to="/dashboard"  className="cncl-btn">CANCEL ORDER</NavLink>
                

                </div>
                <div className="col-lg-5">
                <ConfirmOrderSummery amount={TotalAmount}  />
                
                <ul className='coupon-list'>
                    {couponsList&&couponsList.map((coupons, i)=>{
                        return (
                            <li key={i}  className={`${isapply == coupons && 'active'}`}>
                                <div className="coupons-card">
                                    <h3>{coupons?.couponCode}</h3>
                                    <p>{coupons?.shortDescription}</p>
                                    <span><b>Expire in:</b> { moment(coupons?.endDate).format("DD-MM-YYYY")}</span>
                                    <button onClick={()=>CouponHandle(coupons, coupons?.discount?.$numberDecimal)}> {isapply == coupons ?<> Applied <i className="fa-solid fa-check"></i></> : 'Apply'} </button>

                                </div>
                            </li>
                        )
                    })}

                </ul>
                
                <button className="dwnld-invc" onClick={PayHandle}>Pay Now</button>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ConfirmOrder