import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    token: null,
    status: '',
    isloading: false,
    data:{},
    error:{},   
    productListstatus:false,
    ZoneListResponse:{},
    SignupResponse:{},
    LoginResponse:{},
    resetotpResponse:{},
    validateotpResponse:{},
    resendotpResponse:{},
    CategoryListResponse:{},
    productListResponse:{},
    DashboardBannerListResponse:{},
    GetSlotListResponse:{},
    GetTimeSlotResponse:{},
    PaymentResponse:{},
    ConfirmPaymentResponse:'',
    GetAboutResponse:'',
    signupobj:null
}

const FetchDataReducer = createSlice({
    name: 'FetchDataReducer',
    initialState,
    reducers: { 
        //Zone
        ZoneRequest(state, action) {
        state.status = action.type;
        state.isloading = true;
      },
      ZoneListSuccess(state, action) {
        state.ZoneListResponse = action.payload;
        state.status = action.type;
        state.isloading = false;
      },
      ZoneListFailure(state, action) {
        state.error = action.payload;
        state.status = action.type;
        state.isloading = false;
      },

       //signup
       SignupRequest(state, action) {
        state.status = action.type;
        state.isloading = true;
      },
      SignupSuccess(state, action) {
        state.SignupResponse = action.payload;
        state.status = action.type;
        state.isloading = false;
      },
      SignupFailure(state, action) {
        state.error = action.payload;
        state.status = action.type;
        state.isloading = false;
      },

      //login
      LoginRequest(state, action) {
        state.status = action.type;
        state.isloading = true;
      },
      LoginSuccess(state, action) {
        state.LoginResponse = action.payload;
        state.status = action.type;
        state.isloading = false;
      },
      LoginFailure(state, action) {
        state.error = action.payload;
        state.status = action.type;
        state.isloading = false;
      },

      //validateotp
      ValidateotpRequest(state, action) {
        state.status = action.type;
        state.isloading = true;
      },
      ValidateotpSuccess(state, action) {
        state.validateotpResponse = action.payload;
        state.status = action.type;
        state.isloading = false;
      },
      ValidateotpFailure(state, action) {
        state.error = action.payload;
        state.status = action.type;
        state.isloading = false;
      },

      //resetotp
      ResetOTPRequest(state, action) {
        state.status = action.type;
      },
      ResetOTPSuccess(state, action) {
        state.resetotpResponse = action.payload;
        state.status = action.type;
      },
      ResetOTPFailure(state, action) {
        state.error = action.payload;
        state.status = action.type;
      },

      //resendotp
      ResendOTPRequest(state, action) {
        state.status = action.type;
      },
      ResendOTPSuccess(state, action) {
        state.resendotpResponse = action.payload;
        state.status = action.type;
      },
      ResendOTPFailure(state, action) {
        state.error = action.payload;
        state.status = action.type;
      },

      //category List
      CategoryRequest(state, action) {
        state.status = action.type;
      },
      CategoryListSuccess(state, action) {
        state.CategoryListResponse = action.payload;
        state.status = action.type;
      },
      CategoryListFailure(state, action) {
        state.error = action.payload;
        state.status = action.type;
      },

       //ProductList
       ProductListRequest(state, action) {
        state.status = action.type;
        state.isloading = true;
      },
      productListSuccess(state, action) {
        state.productListResponse = action.payload;
        state.status = action.type;
        state.isloading = false;
        state.productListstatus = true;
      },
      productListFailure(state, action) {
        state.error = action.payload;
        state.status = action.type;
        state.isloading = false;
        state.productListstatus = true;
      },

      //DashboardList
      DashboardBannerRequest(state, action) {
        state.status = action.type;
        state.isloading = true;
      },
      DashboardBannerSuccess(state, action) {
        state.DashboardBannerListResponse = action.payload;
        state.status = action.type;
        state.isloading = false;
      },
      DashboardBannerFailure(state, action) {
        state.error = action.payload;
        state.status = action.type;
        state.isloading = false;
      },


        //get Temp slot
        GetSlotListRequest(state, action) {
          state.status = action.type;
          state.isloading = true;
        },
        GetSlotListSuccess(state, action) {
          state.GetSlotListResponse = action.payload;
          state.status = action.type;
          state.isloading = false;
        },
        GetSlotListFailure(state, action) {
          state.error = action.payload;
          state.status = action.type;
          state.isloading = false;
        },

        //get Time slot
        GetPickupTimeSlotRequest(state, action) {
          state.status = action.type;
          state.isloading = true;
        },
        GetTimeSlotSuccess(state, action) {
          state.GetTimeSlotResponse = action.payload;
          state.status = action.type;
          state.isloading = false;
        },
        GetTimeSlotFailure(state, action) {
          state.error = action.payload;
          state.status = action.type;
          state.isloading = false;
        },


        //get delivery Time slot
        GetDeliveryTimeSlotRequest(state, action) {
          state.status = action.type;
          state.isloading = true;
        },
        GetdeliveryTimeSlotSuccess(state, action) {
          state.GetdeliveryTimeSlotResponse = action.payload;
          state.status = action.type;
          state.isloading = false;
        },
        GetdeliveryTimeSlotFailure(state, action) {
          state.error = action.payload;
          state.status = action.type;
          state.isloading = false;
        },


         //slot Book
         SlotbookRequest(state, action) {
          state.status = action.type;
          state.isloading = true;
        },
        SlotbookSuccess(state, action) {
          state.SlotbookResponse = action.payload;
          state.status = action.type;
          state.isloading = false;
        },
        SlotbookFailure(state, action) {
          state.error = action.payload;
          state.status = action.type;
          state.isloading = false;
        },

          //slot Details
          SlotDetailsRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          SlotDetailsSuccess(state, action) {
            state.SlotDetailsResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          SlotDetailsFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },

        //Get Coupon
        GetCouponRequest(state, action) {
          state.status = action.type;
          state.isloading = true;
        },
        GetCouponSuccess(state, action) {
          state.GetCouponResponse = action.payload;
          state.status = action.type;
          state.isloading = false;
        },
        GetCouponFailure(state, action) {
          state.error = action.payload;
          state.status = action.type;
          state.isloading = false;
        },

        //slot book payment
        SlotpaymentRequest(state, action) {
          state.status = action.type;
          state.isloading = true;
        },
        SlotpaymentSuccess(state, action) {
          state.SlotpaymentResponse = action.payload;
          state.status = action.type;
          state.isloading = false;
        },
        SlotpaymentFailure(state, action) {
          state.error = action.payload;
          state.status = action.type;
          state.isloading = false;
        },


        //Order History
        GetOrderHistoryRequest(state, action) {
          state.status = action.type;
          state.isloading = true;
        },
        GetOrderHistorySuccess(state, action) {
          state.GetOrderHistoryResponse = action.payload;
          state.status = action.type;
          state.isloading = false;
        },
        GetOrderHistoryFailure(state, action) {
          state.error = action.payload;
          state.status = action.type;
          state.isloading = false;
        },

          //Order History Details
          OrderDetailsRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          OrderDetailsSuccess(state, action) {
            state.OrderDetailsResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          OrderDetailsFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },

          //Get invoice
          GetInvoiceRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },

          GetInvoiceSuccess(state, action) {
            state.GetInvoiceResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
       
          GetInvoiceFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },

          //Save Review
          SavereviewRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          SavereviewSuccess(state, action) {
            state.SavereviewResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          SavereviewFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },

          //Get Review
          GetReviewRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          GetReviewSuccess(state, action) {
            state.GetReviewResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          GetReviewFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },


          //Get Banner
          GetbannerRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          GetbannerSuccess(state, action) {
            state.GetbannerResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          GetbannerFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },

          //Get service
          GetserviceRequest(state, action) {
            state.status = action.type;
          },
          GetserviceSuccess(state, action) {
            state.GetserviceResponse = action.payload;
            state.status = action.type;
          },
          GetserviceFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
          },

          //Get Faq
          GetFaqRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          GetFaqSuccess(state, action) {
            state.GetFaqResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          GetFaqFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },

          //Get support
          GetsupportRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          GetsupportSuccess(state, action) {
            state.GetsupportResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          GetsupportFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },

          //Get Wallet
          GetWalletRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          GetWalletSuccess(state, action) {
            state.GetWalletResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          GetWalletFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },

           //Get Profile
           GetProfileRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          GetProfileSuccess(state, action) {
            state.GetProfileResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          GetProfileFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },

          //Get Process
          GetProcessRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          GetProcessSuccess(state, action) {
            state.GetProcessResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          GetProcessFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },

          //Payment

          PaymentRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          PaymentSuccess(state, action) {
            state.PaymentResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          PaymentFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },

            //Payment Success

            ConfirmPaymentRequest(state, action) {
              state.status = action.type;
              state.isloading = true;
            },
            ConfirmPaymentSuccess(state, action) {
              state.ConfirmPaymentResponse = action.payload;
              state.status = action.type;
              state.isloading = false;
            },
            ConfirmPaymentFailure(state, action) {
              state.error = action.payload;
              state.status = action.type;
              state.isloading = false;
            },

              //Get About
           GetaboutRequest(state, action) {
            state.status = action.type;
            state.isloading = true;
          },
          GetaboutSuccess(state, action) {
            state.GetAboutResponse = action.payload;
            state.status = action.type;
            state.isloading = false;
          },
          GetaboutFailure(state, action) {
            state.error = action.payload;
            state.status = action.type;
            state.isloading = false;
          },


          signupdatarequest(state, action) {
            state.signupobj = action.payload;
          },


             //Update Profile
             UpdateProfileRequest(state, action) {
              state.status = action.type;
              state.isloading = true;
            },
            UpdateProfileSuccess(state, action) {
              state.UpdateprofileResponse = action.payload;
              state.status = action.type;
              state.isloading = false;
            },
            UpdateProfileFailure(state, action) {
              state.error = action.payload;
              state.status = action.type;
              state.isloading = false;
            },

             //Forgot Password
             ForgotpasswordRequest(state, action) {
              state.status = action.type;
              state.isloading = true;
            },
            ForgotpasswordSuccess(state, action) {
              state.ForgotpasswordResponse = action.payload;
              state.status = action.type;
              state.isloading = false;
            },
            ForgotpasswordFailure(state, action) {
              state.error = action.payload;
              state.status = action.type;
              state.isloading = false;
            },


            //set Password
            setpasswordRequest(state, action) {
              state.status = action.type;
              state.isloading = true;
            },
            setpasswordSuccess(state, action) {
              state.setpasswordResponse = action.payload;
              state.status = action.type;
              state.isloading = false;
            },
            setpasswordFailure(state, action) {
              state.error = action.payload;
              state.status = action.type;
              state.isloading = false;
            },


    },
  });

  export const {

    setpasswordRequest,
    setpasswordSuccess,
    setpasswordFailure,

    ForgotpasswordRequest,
    ForgotpasswordSuccess,
    ForgotpasswordFailure,


    UpdateProfileRequest,
    UpdateProfileSuccess,
    UpdateProfileFailure,
    

    signupdatarequest,

    GetaboutRequest,
    GetaboutSuccess,
    GetaboutFailure,

    ConfirmPaymentRequest,
    ConfirmPaymentSuccess,
    ConfirmPaymentFailure,

    PaymentRequest,
    PaymentSuccess,
    PaymentFailure,

    GetProcessRequest,
    GetProcessSuccess,
    GetProcessFailure,

    GetProfileRequest,
    GetProfileSuccess,
    GetProfileFailure,

    GetWalletRequest,
    GetWalletSuccess,
    GetWalletFailure,

    GetsupportRequest,
    GetsupportSuccess,
    GetsupportFailure,

    GetFaqRequest,
    GetFaqSuccess,
    GetFaqFailure,

    ZoneRequest,
    ZoneListSuccess,
    ZoneListFailure,

    SignupRequest,
    SignupSuccess,
    SignupFailure,

    LoginRequest,
    LoginSuccess,
    LoginFailure,

    ValidateotpRequest,
    ValidateotpSuccess,
    ValidateotpFailure,

    ResetOTPRequest,
    ResetOTPSuccess,
    ResetOTPFailure,

    ResendOTPRequest,
    ResendOTPSuccess,
    ResendOTPFailure,

    CategoryRequest,
    CategoryListSuccess,
    CategoryListFailure,

    ProductListRequest,
    productListSuccess,
    productListFailure,

    DashboardBannerRequest,
    DashboardBannerSuccess,
    DashboardBannerFailure,

    GetSlotListRequest,
    GetSlotListSuccess,
    GetSlotListFailure,

    GetPickupTimeSlotRequest,
    GetTimeSlotSuccess,
    GetTimeSlotFailure,

    GetDeliveryTimeSlotRequest,
    GetdeliveryTimeSlotSuccess,
    GetdeliveryTimeSlotFailure,

    SlotbookRequest,
    SlotbookSuccess,
    SlotbookFailure,

    SlotDetailsRequest,
    SlotDetailsSuccess,
    SlotDetailsFailure,

    GetCouponRequest,
    GetCouponSuccess,
    GetCouponFailure,

    SlotpaymentRequest,
    SlotpaymentSuccess,
    SlotpaymentFailure,

    GetOrderHistoryRequest,
    GetOrderHistorySuccess,
    GetOrderHistoryFailure,

    OrderDetailsRequest,
    OrderDetailsSuccess,
    OrderDetailsFailure,

    GetInvoiceRequest,
    GetInvoiceFailure,
    GetInvoiceSuccess,

    SavereviewRequest,
    SavereviewSuccess,
    SavereviewFailure,


    GetReviewRequest,
    GetReviewSuccess,
    GetReviewFailure,

    GetbannerRequest,
    GetbannerSuccess,
    GetbannerFailure,

    GetserviceRequest,
    GetserviceSuccess,
    GetserviceFailure,






  
  } = FetchDataReducer.actions;


export default FetchDataReducer.reducer;